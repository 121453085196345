import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";

const Monetize = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <>

            <main className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Box
                                sx={{
                                    py: 1,
                                    px: 10,
                                }}
                            >

                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" },
                                    alignItems: "center",
                                    justifyContent: "start",
                                    //height: "100vh",
                                    px: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >


                                    <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold", color: "#29008B" }}>
                                        Activapost is on its way.
                                    </Typography>
                                    <Typography sx={{ mb: 4, color: "#131010" }}>
                                        We’re working hard to bring you something amazing. This page is currently under development, but it won’t be long before it’s ready!
                                    </Typography>
                                    <Typography sx={{ mb: 4, color: "#131010" }}>
                                        Stay tuned for upcoming updates and exciting features!
                                    </Typography>
                                    <Typography sx={{ color: "#9A00A9" }}>Thank you for your patience and support!</Typography>
                                </Box>

                                <Box
                                    sx={{
                                        flex: 1,
                                        display: { xs: "none", md: "flex" },
                                        justifyContent: "center",
                                        alignItems: "center",
                                        p: 1,
                                    }}
                                >
                                    <img
                                        src="assets/images/navbar/Commingsoon.png"
                                        alt="Tree Design"
                                        style={{ maxWidth: "100%", height: "auto", borderRadius: 8 }}
                                    />
                                </Box>
                            </Box>

                        </div>
                    </div>
                </div>


                <footer
                    style={{
                        background: "linear-gradient(to right, #9A00A9, #580097, #29008B)",
                        color: "white",
                        padding: "40px 20px",
                        marginTop: "40px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0px 17% 0px 10%",
                            marginBottom: "10px",
                            borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                            paddingBottom: "20px",
                        }}
                    >
                        <img
                            src="assets/images/navbar/activa tree logo (2).png"
                            alt="Tree Design"
                            style={{
                                maxWidth: "20%",
                                height: "auto",
                                marginLeft: "11%",
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                gap: "20px",
                                marginLeft: "8%",
                                borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
                                borderRight: "1px solid rgba(255, 255, 255, 0.3)",
                                paddingLeft: "1%",
                                paddingRight: "1%",
                                height: "45px",
                            }}
                        >
                            {[
                                "instagram",
                                "facebook",
                                "x",
                                "linkdin",
                                "tiktok",
                                "youtube",
                                "uo",
                            ].map((icon, index) => (
                                <Link href="#" key={index} sx={{ color: "white" }}>
                                    <img
                                        src={`assets/images/footer/${icon}.png`}
                                        alt={icon}
                                        style={{ width: "24px" }}
                                    />
                                </Link>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            rowGap: "20px",
                            columnGap: "50px",
                            maxWidth: "1200px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                    >
                        {[
                            {
                                title: "Company",
                                links: ["About Us", "Blog", "For Investors"],
                            },
                            {
                                title: "Support",
                                links: ["Help Center", "What's New"],
                            },
                            {
                                title: "Legal",
                                links: ["Privacy Policy", "Terms of Service"],
                            },
                            {
                                title: "Contact Us",
                                details: [
                                    {
                                        text: "info@activatree.com",
                                        icon: "mail",
                                    },
                                    {
                                        text: "State of Georgia, U.S.A",
                                        icon: "location",
                                    },
                                ],
                            },
                        ].map((section, index) => (
                            <Box
                                key={index}
                                sx={{
                                    flex: "1 1 calc(25% - 50px)",
                                    minWidth: "200px",
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: "bold",
                                        fontSize: "16px",
                                        marginBottom: "10px",
                                        color: "white",
                                    }}
                                >
                                    {section.title}
                                </Typography>
                                {section.links ? (
                                    <ul style={{ listStyle: "none", padding: 0 }}>
                                        {section.links.map((link, i) => (
                                            <li key={i}>
                                                <Link
                                                    href="#"
                                                    sx={{
                                                        color: "white",
                                                        textDecoration: "none",
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                    }}
                                                >
                                                    {link}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    section.details?.map((detail, i) => (
                                        <Typography
                                            key={i}
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                color: "white",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <img
                                                src={`assets/images/footer/${detail.icon}.png`}
                                                alt={detail.icon}
                                                style={{
                                                    width: "20px",
                                                    marginRight: "10px",
                                                }}
                                            />
                                            {detail.text}
                                        </Typography>
                                    ))
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            textAlign: "center",
                            marginTop: "30px",
                            borderTop: "1px solid rgba(255, 255, 255, 0.3)",
                            paddingTop: "15px",
                            marginLeft: "18%",
                            marginRight: "18%",
                        }}
                    >
                        <Typography variant="body2" sx={{ color: "white", fontSize: "12px" }}>
                            © 2024 Activatree, a Subsidiary of Infinatree, Inc.
                        </Typography>
                    </Box>
                </footer>
            </main>
        </>
    );
}

export default Monetize;